import { Link } from "react-router-dom";


const FooterMenu = () => {
    return (
        <div className="flex flex-col items-center justify-center h-full">
            <Link to='/' className="hover:underline decoration-text mb-4 px-2">Home</Link>
            <Link to='poster' className="hover:underline decoration-text mb-4 px-2">Build Your Design</Link>
            <Link to='/about-us' className="hover:underline decoration-text mb-4 px-2">About Us</Link>
            <Link to='/contact-us'  className="hover:underline decoration-text mb-4 px-2">Contact</Link>
        </div>
    )
}

export default FooterMenu;