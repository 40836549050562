
import { BiCustomize } from "react-icons/bi";
import { MdOutlineWorkspacePremium } from "react-icons/md";
import { FiPrinter } from "react-icons/fi";
import { TbTruckDelivery } from "react-icons/tb";
import { FaHandHoldingHeart } from "react-icons/fa";
  
const QualityFeatures = () => {
    const features = [
        {
        icon: FiPrinter,
        title: "Museum-Quality Prints",
        description: "Printed on premium 200gsm matte paper with archival-quality inks"
        },
        {
        icon: BiCustomize,
        title: "High Customization",
        description: "Choose from a wide range of customization options to craft your unique style"
        },
        {
        icon: TbTruckDelivery,
        title: "Secure Shipping",
        description: "Carefully packaged and shipped in damage-proof tubes"
        },
        {
        icon: FaHandHoldingHeart,
        title: "Handcrafted Care",
        description: "Each map is carefully checked and handled with attention to detail"
        }
    ];

return (
    <>
    {/* Quality Promise Section */}
    <section className="py-16 bg-gradient-to-b from-accent/10 to-accent/60">
        <div className="max-w-7xl mx-auto px-4">
        <div className="flex flex-col lg:flex-row gap-12 justify-center items-center">
            {/* Left side - Image */}
            <div className="flex-1 relative">
            <div className="aspect-poster-portrait max-w-lg rounded-sm overflow-hidden shadow-2xl">
                
                {/* Floating Quality Badge */}
                <div className="absolute -right-4 -bottom-4 bg-primary text-white p-4 rounded-sm shadow-lg">
                <MdOutlineWorkspacePremium className="w-8 h-8 mb-2" />
                <p className="text-sm font-semibold">Premium Quality</p>
                </div>
            </div>
            </div>

            {/* Right side - Content */}
            <div className="flex-1">
            <h2 className="text-text text-3xl font-bold mb-6">
                Crafted with Quality, 
                <span className="block text-primary">Delivered with Care</span>
            </h2>
            
            <div className="grid sm:grid-cols-2 gap-6">
                {features.map((feature, index) => (
                <div key={index} className="flex gap-4">
                    <div className="p-3 rounded-sm bg-accent/10 text-primary">
                    <feature.icon className="w-6 h-6" />
                    </div>  
                    <div>
                    <h3 className="text-text font-semibold mb-1">{feature.title}</h3>
                    <p className="text-text/70 text-sm">{feature.description}</p>
                    </div>
                </div>
                ))}
            </div>

            </div>
        </div>
        </div>
    </section> 
    </>
    );
};

export default QualityFeatures;

/**{/* Featured Maps Section *}
<section className="py-16 bg-accent/5">
<div className="max-w-7xl mx-auto px-4">
<h2 className="text-3xl font-bold text-center mb-12">
    Featured Designs
</h2>
<div className="flex flex-wrap gap-6 justify-center">
    {[1, 2, 3].map((item) => (
    <div key={item} className="flex-1 min-w-[300px] max-w-[400px] bg-white rounded-xl overflow-hidden shadow-lg hover:shadow-xl transition-shadow">
        <div className="aspect-[4/3] relative">
        <img 
            src={`/api/placeholder/400/${300}`}
            alt={`Featured design ${item}`}
            className="w-full h-full object-cover"
        />
        </div>
        <div className="p-4">
        <h3 className="font-semibold mb-2">Popular Style {item}</h3>
        <p className="text-sm text-text/70">Discover our most loved map designs</p>
        </div>
    </div>
    ))}
</div>
</div>
</section>

{/* Customer Experience Section *}
<section className="py-16 bg-white">
<div className="max-w-7xl mx-auto px-4">
<div className="flex flex-col md:flex-row gap-8 items-center">
    <div className="flex-1 space-y-6">
    <h2 className="text-3xl font-bold">
        Easy Design Process, 
        <span className="block text-primary">Beautiful Results</span>
    </h2>
    <div className="space-y-4">
        <div className="flex gap-4 items-center">
        <Clock className="w-6 h-6 text-primary" />
        <p className="text-lg">Design in minutes, enjoy for years</p>
        </div>
        <div className="flex gap-4 items-center">
        <ImageIcon className="w-6 h-6 text-primary" />
        <p className="text-lg">Preview your design before ordering</p>
        </div>
        <div className="flex gap-4 items-center">
        <ShieldCheck className="w-6 h-6 text-primary" />
        <p className="text-lg">100% satisfaction guaranteed</p>
        </div>
    </div>
    <button className="bg-primary text-white px-6 py-3 rounded-lg hover:bg-secondary hover:text-text transition-colors">
        Start Creating
    </button>
    </div>
    <div className="flex-1">
    <img 
        src="/api/placeholder/500/400" 
        alt="Design Process" 
        className="rounded-xl shadow-lg"
    />
    </div>
</div>
</div>
</section> */