import { Drawer } from '@mui/material';
import { RxCross1 } from 'react-icons/rx';
import CheckoutItem from './CheckoutItem';
import { useState, useEffect } from 'react';
import stripeRequest from '../Utils/Stripe/stripe';
import { sizes } from '../PosterDesigner/utils/styles';
import addDocToFireStore from '../Utils/Firebase/FirebaseConfig';

const DrawerBar = ({Open, toggleDrawer, cart, setCart}) => {
    
    const [ total, setTotal ] = useState(null);

    const handleCheckout = async (cart) =>{

      const items = cart.map(item => {
        
        if (item.Format === 'Digital') {
          const digitalPriceKey = 'price_1PoQqBRruZcEpy4uajlhgjA0'
          return {
            price: digitalPriceKey,
            quantity: item.quantity,
          }
        } else {
          const priceKey = sizes.find(sizeItem => sizeItem.size === item.Size)
          return {
            price: priceKey.priceId,
            quantity: item.quantity,
          } 
        }
      });
      const docRefId =  await addDocToFireStore(cart)
      
      const body = {
        items: items,
        metaData: docRefId
      }

      await stripeRequest(body)
    }

    useEffect(()=> {
        if (cart && cart.length > 0){
        setTotal(cart.reduce((total, item) => total + (item.Price * item.quantity),0));
        } else{
          setTotal(null)
        }
    },[cart])

    return (
      <div>
        <Drawer
          anchor="right"
          open={Open}
          onClose={toggleDrawer}
          sx={{
            '& .MuiDrawer-paper': {
              backgroundColor: '#F2F5EF',
              width: {xs: '90%', sm: '60%', md: '50%', lg: '450px'},
            },
          }}
        >
        <div className='flex items-center justify-between py-4 px-6 bg-accent/100'>
        <h1 className='text-2xl font-bold text-text'>Your Cart</h1>
        <RxCross1 className='cursor-pointer' onClick={toggleDrawer}/>
        </div>

        <div className='w-full flex flex-col items-center justify-between h-screen bg-gradient-to-b from-accent/100  to-accent/70'>
            <div className='w-full'>    
            <CheckoutItem cart={cart} setCart={setCart}/>
            </div>
            <div className='flex flex-wrap justify-between gap-2 items-center bg-accent w-full px-4'>
                <div className='font-semibold text-text'>{`Subtotal: ${total? `$${total.toFixed(2)}`: ''}`}</div>
                <button onClick={()=> {handleCheckout(cart)}} className={`text-sm lg:text-base font-semibold rounded-sm bg-primary text-white hover:shadow-xl hover:bg-secondary hover:text-text py-2 px-12 my-4`}>Checkout</button>
            </div>
        </div>
        </Drawer>
      </div>
    )
  }

export default DrawerBar;