import { Link } from "react-router-dom";
import { BiShoppingBag } from "react-icons/bi";
import DrawerBar from "../CheckoutDrawer/Drawer";

const NavDesktop = ({Open, toggleDrawer, cart, setCart}) => {


    return (
        <>
        <nav className="hidden md:block bg-accent/60 text-text py-4 px-4">
            <div className="flex items-center justify-between">
                <div className="flex space-x-28">
                <div>
                    <img src='/logo_design.png' alt='company_logo' style={{width:'30px', height:'30px'}}/>
                </div>
                <div className="font-normal space-x-6">
                    <Link to='/' className="hover:underline decoration-text">Home</Link>
                    <Link to='poster' className="hover:underline decoration-text">Build Your Design</Link>
                    <Link to='/about-us' className="hover:underline decoration-text">About Us</Link>
                    <Link to='/contact-us'  className="hover:underline decoration-text">Contact</Link>
                </div>
                </div>
                <div onClick={toggleDrawer} className="cursor-pointer flex items-center h-full py-2 px-6 rounded-md">
                <div className="pr-1 font-semibold">Cart</div>
                <div className="relative">
                <BiShoppingBag/>
                <div className={`${cart.length? 'flex items-center justify-center': 'hidden'} absolute top-[-15px] right-[-15px] rounded-full bg-primary text-white w-6 h-6`}>{cart.length}</div>
                </div>
                <DrawerBar Open={Open} toggleDrawer={toggleDrawer} cart={cart} setCart={setCart}/>
                </div>
            </div>
            
        </nav>
        
        </>
    )
}

export default NavDesktop;