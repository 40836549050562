import { Link } from "react-router-dom";


const FooterInfo = ()=> {
    return (
        <div className="flex flex-col items-center justify-center h-full">
            <Link to='/' className="hover:underline decoration-text mb-4 px-2">Shipping & Returns</Link>
            <Link to='/' className="hover:underline decoration-text mb-4 px-2">Terms & Conditions</Link>
        </div>
    )
}

export default FooterInfo;


