import Hero from "./Hero";
import HomeCarousel from "./Carousel";
import HowItWorks from "./HowItWorks";
import DesignOptions from "./DesignOptions";
import QualityFeatures from "./QualitySection";

const Home = () => {
    return(
        <div>
            {/*<HomeCarousel/>*/}
            <Hero/>
            <HowItWorks/>
            <DesignOptions/>
            <QualityFeatures/>
        </div>
    ) 
}

export default Home;