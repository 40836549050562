import FooterInfo from "./FooterInfo";
import FooterMenu from "./FooterMenu";
import MailingList from "./MalingList";
import { useState } from "react";

const Footer = () =>{

    const [ mailingList, setMailingList ] = useState('');


    return (
    <div className="flex flex-col md:flex-row w-full bg-accent/60 h-full md:h-60">
    <div className="flex-1">Logo</div>
    <div className="flex-1">
        <FooterMenu/>
    </div>
    <div className="flex-1">
        <FooterInfo/>
    </div>
    <div className="flex-1">
        <MailingList mailingList={mailingList} setMailingList={setMailingList}/>
    </div>
    </div>
    )
}

export default Footer;