import React from 'react';
import { FaMapMarkedAlt, FaPrint} from 'react-icons/fa';

const AboutUs = () => {
  return (
    <div className="min-h-screen">
    <div className='bg-gradient-to-b from-accent/60 to-accent/10'>
      {/* Mission Section */}
      <section className="py-16 px-4 md:px-8">
        <div className="flex flex-col items-center max-w-4xl mx-auto">
        <p className="text-5xl font-semibold text-primary mb-12 text-center">
            Our Journey with Maps
        </p>
          <p className="text-lg text-text leading-relaxed mb-8 text-center">
            Maps are powerful—they guide us, they inspire us, and they remind us of the places we cherish.
            We believe every location holds a story worth telling. Whether it's where you met your significant other, 
            the city where your children were born, or your favorite travel destination - we help you transform these 
            meaningful places into elegant wall art so your favorite places are never too far away.
            </p>
            <p className="text-lg text-text leading-relaxed mb-8 text-center">
            Our mission is simple: to give you the power to turn those places into meaningful works of art, 
            creating personalized designs that are uniquely yours.
            </p>
        </div>
      </section>

      {/* Why Custom Map Posters */}
      <section className="py-16 px-4 md:px-8">
        <div className="flex flex-col items-center max-w-4xl mx-auto">
          <p className="text-5xl font-semibold text-text mb-12 text-center">
            Why Custom Map Posters?
          </p>
          <div className="flex flex-col md:flex-row gap-8 w-full">
            <div className="flex-1 p-6 rounded-sm bg-accent shadow-lg">
              <div className="flex flex-col space-y-4">
                <div className="flex items-center justify-center space-x-4">
                  <FaMapMarkedAlt className="w-8 h-8 text-primary" />
                  <p className='text-2xl text-text'>Personalized Design</p>
                </div>
                <p className="text-text text-base">
                  Every map is uniquely crafted to your specifications, allowing you to capture exactly what makes your chosen location special.
                </p>
              </div>
            </div>
            <div className="flex-1 p-6 rounded-sm bg-accent shadow-lg">
              <div className="flex flex-col space-y-4">
                <div className="flex items-center justify-center space-x-4">
                  <FaPrint className="w-8 h-8 text-primary" />
                  <p className='text-2xl text-text'>Premium Quality</p>
                </div>
                <p className="text-text text-base">
                  We use only the finest materials and printing techniques to ensure your map poster stands the test of time.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      </div>

      {/* Quality Materials */}
      <div className='bg-gradient-to-b from-accent/10 to-accent/60'>
      <section className="py-16 px-4 md:px-8">
        <div className="flex flex-col items-center max-w-4xl mx-auto">
        <p className="text-5xl font-semibold text-primary mb-12 text-center">
            Our Quality Promise
          </p>
          <div className="flex flex-col space-y-6 w-full">
            <p className="text-lg text-text leading-relaxed text-center">
              We partner with premium paper suppliers and use archival-quality inks to ensure your map poster remains vibrant for years to come. Each print undergoes rigorous quality checks before being carefully packaged for shipping.
            </p>
            <div className="text-text flex flex-col md:flex-row justify-between gap-6 mt-8">
              <div className="flex-1 text-center">
                <p className="font-semibold text-primary">Museum-Quality Paper</p>
                <p>220gsm matte finish</p>
              </div>
              <div className="flex-1 text-center">
                <p className="font-semibold text-primary">Archival Inks</p>
                <p>Fade-resistant colors</p>
              </div>
              <div className="flex-1 text-center">
                <p className="font-semibold text-primary">Premium Frames</p>
                <p>Handcrafted frames</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Production Process */}
      <section className="py-16 px-4 md:px-8">
        <div className="flex flex-col items-center max-w-4xl mx-auto">
        <p className="text-5xl font-semibold text-primary mb-12 text-center">
            Our Production Process
          </p>
          <div className="flex flex-col items-between mx-auto justify-center space-y-8">
            <div className="flex items-center space-x-6">
              <div className="bg-primary text-white rounded-full w-8 h-8 flex items-center justify-center flex-shrink-0">
                1
              </div>
              <div className='w-full'>
                <p className="text-2xl text-text/90 mb-2">Design Creation</p>
                <p className="text-text text-lg">
                  Your custom design is prepared by our mapping experts
                </p>
              </div>
            </div>
            <div className="flex items-center space-x-6">
              <div className="bg-primary text-white rounded-full w-8 h-8 flex items-center justify-center flex-shrink-0">
                2
              </div>
              <div className='w-full'>
                <p className="text-2xl text-text/90 mb-2">Quality Check</p>
                <p className="text-text text-lg">
                Each design is reviewed for accuracy and aesthetic appeal
                </p>
              </div>
            </div>
            <div className="flex items-center space-x-6">
              <div className="bg-primary text-white rounded-full w-8 h-8 flex items-center justify-center flex-shrink-0">
                3
              </div>
              <div className='w-full'>
                <p className="text-2xl text-text/90 mb-2">Premium Printing</p>
                <p className="text-text text-lg">
                Printed using top-of-the-line equipment and materials
                </p>
              </div>
            </div>
            <div className="flex items-center space-x-6">
              <div className="bg-primary text-white rounded-full w-8 h-8 flex items-center justify-center flex-shrink-0">
                4
              </div>
              <div className='w-full'>
                <p className="text-2xl text-text/90 mb-2">Careful Packaging</p>
                <p className="text-text text-lg">
                Securely packed to ensure safe delivery to your door
                </p>
                {/* <Typography variant="h6" className="mb-2">Careful Packaging</Typography>
                <Typography variant="body1" className="text-slate-600">
                  Securely packed to ensure safe delivery to your door
                </Typography> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      </div>
    </div>
  );
}

export default AboutUs;