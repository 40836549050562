
import NavDesktop from "./NavDesktop";
import NavMobile from "./NavMobile";

const NavBar = ({cart, setCart, Open, toggleDrawer}) => {


    return (
        <>
        <NavDesktop Open={Open} toggleDrawer={toggleDrawer} cart={cart} setCart={setCart}/>
        <NavMobile Open={Open} toggleDrawer={toggleDrawer} cart={cart} setCart={setCart}/>
        </>
    )
}

export default NavBar;