import { useRef } from "react";
import { emailJs } from "../Utils/NodeMailer/nodeMailer";

const MailingList = ({mailingList, setMailingList}) =>{

    const form = useRef();

    const handleChange = (e) =>{
        setMailingList(e.target.value);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(mailingList) {
            await emailJs(form.current, 'template_s143nab');
        }
        console.log('Subscriber Email: ', mailingList);
    }

    return (
        <div ref={form} className="flex flex-col items-center justify-center h-full">
            <form onSubmit={handleSubmit} className='rounded-sm text-text  m-4 py-4 px-6'>
            <label><h1 className='pb-2 text-text font-normal text-base'>Join our list to get the latest offers and discounts</h1></label>
            <div className="flex flex-col lg:flex-row gap-3">
            <input value={mailingList} onChange={handleChange} type='email' name='email' placeholder="Email" required className="block w-full p-2 text-sm bg-background rounded-sm border border-accent mb-3"/>
            <button type='submit' className={`text-xs lg:text-sm font-semibold rounded-sm hover:shadow-xl bg-primary text-white py-2 px-4 mb-3`}>Submit</button>
            </div>
            </form>
        </div>
    )

}

export default MailingList;