import { LuMinus, LuPlus } from "react-icons/lu";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BsCartX } from "react-icons/bs";
import { sizes } from "../PosterDesigner/utils/styles";

const CheckoutItem = ({cart, setCart}) =>{

    const removeCartItem = (itemId) => {
        setCart((prevCart) => prevCart.filter(item => item.id !== itemId));
    }

    const increment = (itemId) => {
        setCart(prevCart => prevCart.map((item) => item.id === itemId ? {...item, quantity: item.quantity + 1} : item));
    }

    const decrement = (itemId) => {
        setCart(prevCart => prevCart.map((item)=> item.id === itemId && item.quantity > 1 ? {...item, quantity: item.quantity - 1} : item))
    }

    return (
        <>
        {cart.length > 0 ? cart.map(cartItem => {
            // Ensure cartItem is defined and has the necessary properties
            if (!cartItem || !sizes[cartItem.Size] || !cartItem.Format) return null;

            return (
                <div key={cartItem.id} className="border-b-2 mb-2 border-primary/20 bg-background/20">

                    <div className="flex w-full py-4">
                        <div className="basis-1/4 min-h-[150px]">
                        <img className="px-2 w-full h-full object-contain" src={cartItem.Image} alt="cart-item-image" />   
                        {/* <img className="p-2" src='/broon.png' alt=''/> */}
                        </div>
                        
                        <div className="p-2 flex flex-col justify-evenly basis-3/4">
                            <div className="text-text font-semibold mb-2">
                                {`${sizes[cartItem.Size].sizeName} ${cartItem.Orientation} Poster - ${cartItem.Format}`}
                            </div>
                            <div className="flex items-center text-text text-sm mb-2 bg-accent w-20 p-2 rounded-md">
                                <LuMinus className='cursor-pointer text-text' onClick={()=>decrement(cartItem.id)}/>
                                <div className="w-8 mx-2 text-center">{cartItem.quantity}</div>
                                <LuPlus className='cursor-pointer text-text' onClick={()=>increment(cartItem.id)}/>
                            </div>
                            <div className="flex items-center justify-between mb-2">
                                <div className="text-text font-semibold">{`$ ${(cartItem.Price*cartItem.quantity).toFixed(2)}`}</div>
                                <div className="pr-4">
                                    <RiDeleteBin6Line 
                                        onClick={() => removeCartItem(cartItem.id)} 
                                        className='cursor-pointer text-gray-500' 
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}) 
            : 
            <div className="flex gap-2 items-center justify-center"> 
            <BsCartX />
            <div className="m-2 text-text">Your cart is empty.</div>
            </div>
        }
        </>
    )
}

export default CheckoutItem;